exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-ai-faqs-in-zendesk-guide-zapier-and-curator-js": () => import("./../../../src/pages/blog/ai-faqs-in-zendesk-guide-zapier-and-curator.js" /* webpackChunkName: "component---src-pages-blog-ai-faqs-in-zendesk-guide-zapier-and-curator-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-optimizing-project-task-lists-in-asana-with-zapier-and-curator-js": () => import("./../../../src/pages/blog/optimizing-project-task-lists-in-asana-with-zapier-and-curator.js" /* webpackChunkName: "component---src-pages-blog-optimizing-project-task-lists-in-asana-with-zapier-and-curator-js" */),
  "component---src-pages-blog-resume-parsing-in-workday-with-zapier-and-curator-js": () => import("./../../../src/pages/blog/resume-parsing-in-workday-with-zapier-and-curator.js" /* webpackChunkName: "component---src-pages-blog-resume-parsing-in-workday-with-zapier-and-curator-js" */),
  "component---src-pages-blog-streamlining-legal-contract-drafting-in-docusign-with-zapier-and-curator-js": () => import("./../../../src/pages/blog/streamlining-legal-contract-drafting-in-docusign-with-zapier-and-curator.js" /* webpackChunkName: "component---src-pages-blog-streamlining-legal-contract-drafting-in-docusign-with-zapier-and-curator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

